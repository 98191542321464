import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import SEO from "../components/global/Seo"

const TextPageTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout breadcrumbs={[{ title: frontmatter.title, url: frontmatter.slug }]}>
      <SEO title={frontmatter.title} />
      <section className="section-padding">
        <div className="container">
          <article
            className="page-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </section>
    </Layout>
  )
}

export default TextPageTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        photo {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
